<template>
  <div class="inService content-index">
    <el-page-header @back="goBack" content="服务中衣物列表"></el-page-header>
    <div class="formBox">
      <el-form
        :model="formData"
        ref="ruleForm"
        label-width="10px"
        class="demo-ruleForm"
      >
        <el-form-item prop="region">
          <el-date-picker
            v-model="dates"
            type="daterange"
            align="right"
            unlink-panels
            size="medium"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="selectTimes"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="clothesNum">
          <el-input
            v-model="formData.clothesNum"
            size="medium"
            placeholder="请输入手机号/衣物号"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="status">
          <el-select
            v-model="formData.status"
            placeholder="全部"
            size="medium"
            clearable
          >
            <el-option
              :label="item.text"
              :value="item.value"
              v-for="item in statusList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item prop="storeId">
          <el-select
            v-model="formData.storeId"
            placeholder="全部门店"
            size="medium"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in storeList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="queryInfo">
            查询
          </el-button>
          <el-button size="medium" @click="reSet">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%;" v-loading="loading">
        <el-table-column label="序号" width="50" type="index"></el-table-column>
        <el-table-column
          prop="serialNumber"
          label="流水号"
          width="180"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clothesNum"
          show-overflow-tooltip
          label="衣物号"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="顾客姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="projectName"
          label="名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="actuallyPaidPrice"
          label="金额"
          align="center"
        ></el-table-column>
        <el-table-column prop="commissionPrice" label="提成"></el-table-column>
        <el-table-column prop="clothesStatus" label="状态"></el-table-column>
        <el-table-column
          prop="orderTime"
          label="收衣时间"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="closedDay"
          label="收衣天数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="storeName"
          label="消费门店"
          align="center"
        ></el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              :disabled="!scope.row.currentStore"
              @click="associatedOrder(scope.row)"
            >
              关联订单
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="formData.current"
          :page-size="formData.size"
          layout="total, prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { serviceData, storeName } from '@/api/commission'
export default {
  name: 'InService',
  data() {
    return {
      loading: false,
      formData: {
        beginTime: '',
        endTime: '',
        // type: '',
        clothesNum: '',
        // status: '',
        storeId: '',
        size: 10,
        current: 1,
      },
      total: 0,
      // dates: [
      //   this.$moment().startOf('month').format('YYYY-MM-DD'),
      //   this.$moment().format('YYYY-MM-DD'),
      // ],
      dates: null,
      pickerOptions: {
        shortcuts: [
          {
            text: '昨天',
            onClick(picker) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, start])
            },
          },
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime()
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 90 * 24 * 3600 * 1000
            const minTime = this.selectDate - one
            const maxTime = this.selectDate + one
            return (
              time.getTime() < minTime ||
              time.getTime() > maxTime ||
              time.getTime() > Date.now() - 8.64e6
            )
          }
        },
      },
      tableData: [],
      // statusList: [
      //   { text: '已完成', value: 1 },
      //   { text: '已退款', value: 2 },
      // ],
      // typeList: [
      //   { text: '收衣', value: 1 },
      //   { text: '办卡', value: 2 },
      //   { text: '充值', value: 3 },
      // ],
      storeList: [],
    }
  },
  created() {
    this.selectTimes()
    this.getStoreName()
    this.pageInfo()
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    selectTimes() {
      this.formData.beginTime = this.dates ? this.dates[0] : ''
      this.formData.endTime = this.dates ? this.dates[1] : ''
    },
    async getStoreName() {
      const { data: res } = await storeName()
      this.storeList = res
    },
    async pageInfo() {
      this.loading = true
      try {
        const { data: res } = await serviceData(this.formData)
        console.log(res)
        if (res.code != 0) {
          this.$message.error(res.msg)
          this.loading = false
          return
        }
        this.tableData = res.data.records
        this.total = res.data.total
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    queryInfo() {
      this.formData.current = 1
      this.pageInfo()
    },
    reSet() {
      this.$refs.ruleForm.resetFields()
      this.dates = null
      this.selectTimes()
      this.pageInfo()
    },
    handleCurrentChange(val) {
      this.formData.current = val
      this.pageInfo()
    },
    associatedOrder(data) {
      console.log(data.id)
      this.$router.push({
        path: '/home/order',
        query: {
          orderNumber: data.serialNumber,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.inService {
  padding: 20px;
  .formBox {
    margin-top: 30px;
    .demo-ruleForm {
      display: flex;
    }
  }
  .footer {
    margin-top: 15px;
    text-align: right;
  }
}
</style>
